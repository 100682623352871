import React from 'react';
import gsap from 'gsap';
import Rellax from 'react-rellax';
import { FiChevronLeft } from "react-icons/fi";
import { FaLongArrowAltLeft, FaLongArrowAltRight} from 'react-icons/fa'
import { Link } from 'react-router-dom';

function Set() {
  window.scrollTo(0, 0);
  setTimeout(function() {
    animate();
  }, 0);
  // window.addEventListener('load', animate);
  window.addEventListener('scroll', detailAnimate);
  // window.addEventListener('resize', () => {
  //   window.location.reload(true);
  // });

  function animate() {
    document.querySelector('#proj-cover img').classList.remove('hidden');
    document.querySelector('.backbtn').classList.remove('hidden');
    document.querySelector('#proj-cover').classList.add('sethero');
    let t1 = gsap.timeline();
    t1.from('#proj-overview', {y: 150, opacity: 0, duration: .6, ease: 'power1.out'});
    t1.from('.proj-revealer', {width: '101%', duration: .5, ease: 'power1.out', delay: .3});
    t1.from('#proj-cover img', {y: -200, opacity: 0, duration: 1, ease: "elastic.out(1, 0.6)"});
    t1.from('.backbtn', {x: -100, opacity: 0, duration: .3, ease: 'power1.out'});
  }

  function detailAnimate() {
    let t2 = gsap.timeline();
    t2.from('.detail', {y: 50, opacity: 0, duration: 1, stagger: .4, delay: .3});
    window.removeEventListener('scroll', detailAnimate);
  }

return (
    <div className='App'>
      <section id='proj-intro'>
        <Link to='/'>
          <button className='backbtn hidden'><FiChevronLeft/>Home</button>
        </Link>
        <div id='proj-cover'>
          <div className='proj-revealer'/>
          <Rellax speed={-3}>
            <img className='hidden' src='images/cards.png' alt ='pokeball'/>
          </Rellax>
        </div>
      </section>

      <section id='proj-overview'>
          <div id='proj-name'>
            <div>
              <h2 id='number'>06</h2>
              <p>Web Development</p>
              <h1>Set</h1>
            </div>
          </div>
          <div id='proj-details'>
            <div className='detail'>
              <p>ROLE</p>
              <p>Developer</p>
            </div>
            <div className='detail'>
              <p>DURATION</p>
              <p>1 Week</p>
            </div>
            <div className='detail'>
              <p>DATE</p>
              <p>May, 2020</p>
            </div>
          </div>
        </section>

      <section className='content'>
        <div id='introduction' className='process-section'>
          <div className='process-title'>
            <p>00 /</p>
            <h1>Introduction</h1>
          </div>
          <video autoPlay muted loop id="projectVideo">
            <source src="images/setvid.mov" type="video/mp4"/>
          </video>
          <div className='process-text'>
            <p>
              This class assignment was my introduction to JavaScript coding. My sole objective was to provide
              the behavior for this virtual version of the card game "Set."
            </p>
            <p>
              This the premise of the game is find 3 cards that make up a set. In order to make a set
              the cards must have similar characteristics (shape, number, color, or pattern).
            </p>
            <a href='https://onlinesetgame.firebaseapp.com/' id='defaultS' className='highlight' target='_open'>
              Visit Website</a>
          </div>
        </div>

        <div id='role' className='process-section'>
          <div className='process-title'>
            <p>01 /</p>
            <h1>Challenges</h1>
          </div>
          <div className='process-text'>
            <p>The main challenge I encountered during this assignment was monitoring which
              cards were currently on the board. The spec outlined that at any given time no two cards
              should ever be the same.
            </p>
          </div>
        </div>

        <div id='work' className='process-section'>
          <div className='process-title'>
            <p>02 /</p>
            <h1>Reflection</h1>
          </div>
          <div className='process-text'>
            <p>
              This assignment taught me the basics of JavaScript. I learned how to manipulate the DOM
              tree using query selectors, event listeners, and by adding/removing classes or elements.
              Most importantly I learned how to read the documentation from the Mozilla Developer Network.
            </p>
          </div>
        </div>

        <section id='otherproj'>
        <a href='/airbnb'>
          <div id='previous' className='cta'>
            <div>
              <img src='images/airbnb.png' alt='Airbnb creative jam'/>
            </div>
            <div>
              <p>PREVIOUS</p>
              <h3>Airbnb &mdash; Creative Jam</h3>
              <FaLongArrowAltLeft/>
            </div>
          </div>
        </a>
        <Link to='/pokedex'>
          <div id='next' className='cta'>
            <div>
              <img src='images/pokemon.png' alt='pokedex web development assignment'/>
            </div>
            <div>
              <p>UP NEXT</p>
              <h3>Pokedex &mdash; Web Development</h3>
              <FaLongArrowAltRight/>
            </div>
          </div>
        </Link>
      </section>
      </section>
    </div>
  );
}

export default Set;