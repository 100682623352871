import React from 'react';
import gsap from 'gsap';
import Rellax from 'react-rellax';
import { FiChevronLeft } from "react-icons/fi";
import { FaLongArrowAltLeft, FaLongArrowAltRight} from 'react-icons/fa'
import { Link } from 'react-router-dom';

function Tidy() {
  window.scrollTo(0, 0);
  setTimeout(function() {
    animate();
  }, 0);
  // window.addEventListener('load', animate);
  window.addEventListener('scroll', detailAnimate);
  // window.addEventListener('resize', () => {
  //   window.location.reload(true);
  // });

  async function animate() {
    document.querySelector('#proj-cover img').classList.remove('hidden');
    document.querySelector('.backbtn').classList.remove('hidden');
    await document.querySelector('#proj-cover').classList.add('tidyhero');
    let t1 = gsap.timeline();
    t1.from('#proj-overview', {y: 150, opacity: 0, duration: .6, ease: 'power1.out'});
    t1.from('.proj-revealer', {width: '101%', duration: .5, ease: 'power1.out', delay: .3});
    t1.from('#proj-cover img', {y: -200, opacity: 0, duration: 1, ease: "elastic.out(1, 0.6)"});
    t1.from('.backbtn', {x: -100, opacity: 0, duration: .3, ease: 'power1.out'});
  }

  function detailAnimate() {
    let t2 = gsap.timeline();
    t2.from('.detail', {y: 50, opacity: 0, duration: 1, stagger: .4, delay: .3});
    window.removeEventListener('scroll', detailAnimate);
  }

return (
    <div className='App'>
      <section id='proj-intro'>
        <Link to='/'>
          <button className='backbtn hidden'><FiChevronLeft/>Home</button>
        </Link>
        <div id='proj-cover'>
          <div className='proj-revealer'/>
          <Rellax speed={-3}>
            <img className='hidden' src='images/tidygroupmockup.png' alt ='Tidy case study project'/>
          </Rellax>
        </div>
      </section>

      <section id='proj-overview'>
          <div id='proj-name'>
            <div>
              <h2 id='number'>02</h2>
              <p>Case Study</p>
              <h1>Tidy</h1>
            </div>
          </div>
          <div id='proj-details'>
            <div className='detail'>
              <p>ROLE</p>
              <p>Lead Product Designer</p>
            </div>
            <div className='detail'>
              <p>DURATION</p>
              <p>10 Weeks</p>
            </div>
            <div className='detail'>
              <p>DATE</p>
              <p>Dec, 2020</p>
            </div>
          </div>
        </section>

      <section className='content'>
        <div id='introduction' className='process-section'>
          <div className='process-title'>
            <p>00 /</p>
            <h1>Introduction</h1>
          </div>
          <div className='process-text'>
            <h2>Context</h2>
            <p>Unequal participation of household duties and lack of accountability can
              lead to <strong id='tidyS' className='highlight'>miscommunication</strong>, <strong id='tidyS' className='highlight'>disorganization</strong>,
              and <strong id='tidyS' className='highlight'>unwelcome tension between roommates</strong>.</p>

            <p>Tidy, is a household management system that would not only increase the livability
              of a household, but also alleviate potential disputes and help maintain relationships.
              Aspects of shared living that Tidy addresses include automatic chore delegation
              and chore accountability.</p>
          </div>
        </div>

        <div id='research' className='process-section'>
          <div className='process-title'>
            <p>01 /</p>
            <h1>Research</h1>
          </div>
          <div className='process-text'>
            <p>The goal of our reasearch was to gather a comprehensive collection of data and
              observations across a wide variety of living situations including: dorms, shared apartments,
              and even families.</p>

            <p>Our research focused on these aspects of shared living:&nbsp;
              type of living situation, household chores, roommate relationships, conflict resolution, technology aids,
              and roommate relationships.</p>

            <p>We chose to conduct semi-structured interviews as our primary design research method.
              The main advantage of interviews was the level of depth and detail it allowed
              for, especially in regards to specific household practices and circumstances.</p>

            <h2>Research Findings</h2>
            <p>Our research revealed a number of common patterns, most of which
              stemmed from a lack of household organization.</p>
          </div>
          <div className='barsection'>
            <h3>HIGH LEVEL THEMES</h3>
            <div className='tidygrid'>
              <div className='finding'>
                <h4><strong>01. </strong>Unequal participation</h4>
                <p id='tidyS'>"It is hard to force someone to do something. Sometimes you just end up
                  doing their chores for them or saying, lets do it together."</p>
              </div>
              <div className='finding'>
                <h4><strong>02. </strong>Lack of guidelines</h4>
                <p id='tidyS'>"Sometimes people have different standards or definitions of what constitutes clean."</p>
              </div>
              <div className='finding'>
                <h4><strong>03. </strong>Utilize pre-existing tools</h4>
                <p id='tidyS'>"We like to use multiple tools like Venmo, Facebook, and Excel to keep track of
                  expenses and facilitate communication"</p>
              </div>
              <div className='finding'>
                <h4><strong>04. </strong>Prior relationship history</h4>
                <p id='tidyS'>Roommates who shared a previous bond generally maintained more
                  open communication and had no issues with confronting each other.
                  Unlike roommates who are less familiar with each other.</p>
              </div>
              <div className='finding'>
                <h4><strong>05. </strong>Lack managment system</h4>
                <p id='tidyS'>Some households rely on word of mouth or an authority figure to give direction. Without
                  a formalized tool, responsibilities can be easily forgotten.
                </p>
              </div>
              <div className='finding'>
                <h4><strong>06. </strong>Always busy</h4>
                <p id='tidyS'>For college students especially finding time for chores is tough. "it would
                  be nice to know others' schedules so we could plan chores accordingly."
                </p>
              </div>
            </div>
          </div>

          <div className='process-text'>
            <h2>Target Demographic</h2>
            <p>Based on our research we decided to tailor our design towards dysfunctional
              households that lack authoritative figures. As households that already have a well organized
              system or a person of authority telling them what to do would not require the use of a the
              third party management tool.</p>

            <h2>Design Requirements</h2>
            <p>Using the information obtained from our high level themes we developed a list of design
              requirements that our system should attempt to address.</p>

            <p>Our system should:</p>
            <ol>
              <li>Help <strong>communicate</strong> agreed upon household guidelines.</li>
              <li><span id='tidyS' className='highlight'><strong>encourage and promote</strong> chore accountability.</span></li>
              <li>Help <strong>delegate</strong> chores fairly.</li>
              <li><strong>Consider</strong> individual schedules in regards to chore distribution.</li>
              <li><span id='tidyS' className='highlight'><strong>Track and monitor</strong> shared household expenses.</span></li>
              <li><strong>Maintain</strong> positive roommate relationships.</li>
            </ol>

            <p>Given the scope and timeline of our project we decided to focus our design on 2 of the 6
              requirements. The two tasks we decided upon were, promoting chore accountability and
              tracking shared expenses.</p>
          </div>
          <img src='images/tidymessy.jpeg' alt='messy room'/>
        </div>

        <div id='ideation' className='process-section'>
          <div className='process-title'>
            <p>02 /</p>
            <h1>Ideation</h1>
          </div>
          <div className='process-text'>
            <h2>Problem Statement</h2>
            <p id='tidyS' className='statement'>How might we design a system that helps delegate chores and promotes chore
                accountability?</p>

            <h2>Exploring different design directions</h2>
            <p>Designing a new household management system had the potential to take many
              forms. We brainstormed a multitude of concepts, some rational, others
              a bit more radical. Nevertheless, exploring different design directions gave us a breadth
              of interesting features to pull from and incorporate into our final design.</p>

            <h3>App Concept</h3>
            <p>This app would allow users to check the completion status of certain chores, as
              well as the housemate responsible for completing the chore. Communication is
              a primary focus of this design - as a result, the app includes a messaging
              feature that allows roommates to send quick reminders or updates to one another.</p>
          </div>
          <img src="https://imgur.com/11aK59e.jpg" alt='App Concept'/>
          <div className='process-text'>
            <h3>Smartboard Concept</h3>
            <p>Reimagining the common whiteboarding experience that some households
              still use to manage their homes. Aims to preserve the traditional writing
              experience while also allowing users to swap between multiple interfaces.
              One of the primary features of this design is that users would be able to leave
              anonymous comments on the board, serving as a non-aggressive way to remind
              roommates to do something.</p>
          </div>
          <img src="https://imgur.com/9fowQey.png" alt='Smartboard Concept'/>
          <div className='process-text'>
            <h3>"Mr. Clean"</h3>
            <p>Mr. Clean would serves as a 3rd-party
              household mediator, primarily functioning to preserve the peace by alleviating the
              pressure of roommate confrontation. Mr. Clean would routinely patrol the house and scan
              high priority rooms such as the bathroom, kitchen, and living room. If Mr. Clean
              detects a messy environment, he will actively seek out the roommate responsible for
              maintaining that specific room and remind them to do their chores.</p>
          </div>
          <img src="https://imgur.com/bi3clmf.jpg" alt='Robot Concept'/>
          <div className='process-text'>
            <h2>Selected Design</h2>
            <p>We decided to move forward with the
              app design. Some of the deciding factors for choosing an app were based on&nbsp;
              <strong>feasibility</strong>,&nbsp;
              <strong>accessibility</strong>,&nbsp;
              <strong>practicality</strong>.
              We figured an app made the most sense given that everyone has a smartphone, and allows
              people to manage their homes while on the go.</p>

            <h2>Storyboarding</h2>
            <p>To help illustrate how our app design would solve our two tasks outlined above
              we developed storyboards. Each storyboard highlights key events and interactions
              users would execute in order to complete the desired task.</p>

            <h3>Storyboard: Maintaining chore accountability</h3>
            <p>The dishes have not been completed yet. As a result
              the person assigned to the chore gets a notification reminding them to complete it.
              Having seen the alert the user goes and completes the chore. Then after the chore
              is completed, they take a verification photo.</p>
          </div>
          <img src="https://imgur.com/Yf0JEqi.jpg" alt='Chore accountability storyboard'/>
          <div className='process-text'>
            <h3>Storyboard: Tracking shared expenses</h3>
            <p>In this scenario the roommate notices that some of the shared items
              in the kitchen have been used up. So, the user heads to the store and restocks on
              the items. After purchasing the items, the user inputs what they bought
              and the other roommates are notified of the new expense.</p>
          </div>
          <img src="https://imgur.com/uSHahgs.jpg" alt='Shared expenses storyboard'/>
          <div className='process-text'>
            <h2>Initial Information Architecture</h2>
            <p>To better visualize the flow of interactions and how users would navigate through
              our app we developed a quick information architecture (IA).</p>
          </div>
          <img src="https://imgur.com/9OgkLlM.png" alt='Initial information architecture'/>
        </div>

        <div id='prototype' className='process-section'>
          <div className='process-title'>
            <p>03 /</p>
            <h1>Prototype</h1>
          </div>
          <div className='process-text'>
            <p>In order to test the effectiveness of our ideas and flow of interactions we put together
                a low fidelity paper prototype.</p>
          </div>
          <img className='bw white' src="https://imgur.com/gFUabuj.jpg" alt='Initial prototype'/>
          <div className='process-text'>
            <h2>Usability Testing</h2>
            <p>We set up 3 usability tests sessions, each with a participant that fit our target demographic.
              For each test we gave our participants the following tasks:</p>
            <ol>
              <li>Prioritize the "garbage" chore to remind your roommate to take the garbage out.</li>
              <li>Complete your assigned chore "dishes".</li>
              <li>View your shared grocery expenses and reimburse your roommate for the money you owe.</li>
            </ol>

            <h3>Finding #1: Too broad and muddied mental models</h3>
            <p>We tried incorporating various finance-related functionalities and messaging features
              into a chore based app, which led to our participants' mental model being heavily
              divided between different modes. Popular products such as Venmo and Facebook
              Messenger already offer these features at a premium level.</p>

            <h3>Finding #2: Failure to understand significance of prioritizing</h3>
            <p>Participants were often left confused about what the prioritization of chores did.
              This was partially due to the design of our prototype as we forgot to design a screen
              showing what happend once a user clicked the prioritize button.</p>

            <h3>Finding #3: Unnecessary picture confirmation</h3>
            <p>We received feedback that this feature felt like a burden on the usability of the
              design and would be completely unnecessary in a house with a basic foundation of trust.
              "If I trust my roommates I don't need them to send me a picture confirmation every time
              they finish their chores."</p>

            <h2>Solution & Iteration</h2>
            <p>We decided to reorient Tidy’s attention to focus solely on chore management and chore delegation.
              We also got rid of the photo confirmation tool, and rethought the prioritize feature so
              that the most prioritized chores rearrange to be at the top and members assigned to those
              chores get a push notifications.</p>

            <p>With chore management and delegation as our new primary focus we mapped out a new IA.</p>
          </div>
          <img src="https://imgur.com/kq9xr1o.jpg" alt='Revised information architecture' className='white'/>
          <div className='process-text'>
            <p>Tidy now has an onboarding process where new users would have to create an account
              and then "join" or "create" a household. At the creation of every household members
              would add all the chores required to reside in said household followed by the establishment
              of their own personal chore preferences.</p>

            <p>With a clear idea of how our new system flowed we could now revise our prototype and
              conduct our final usability test.</p>
          </div>
          <img className='bw' src="https://imgur.com/UafaE2l.jpg" alt='Revised prototype'/>
          <div className='process-text'>
            <h3>Finding #4: Unclear chore preference settings</h3>
            <p>The household creation process and chore preference settings process
              were back to back in the onboarding process. Rather than setting his own personal
              preferences, our participant thought that he was setting household-wide
              preferences, making him the “authority figure.”</p>

            <h2>Solution</h2>
            <p>To fix this problem we made it so that users would be forced to set their chore preferences
              when they login for the first time. Clearly separating out the two tasks.</p>
          </div>
        </div>

        <div id='design' className='process-section'>
          <div className='process-title'>
            <p>04 /</p>
            <h1>Design</h1>
          </div>
          <div className='process-text'>
            <h2>The Result</h2>
            <p>Our final design was centralized around the themes of <span id='tidyS' className='highlight'>maintaining chore accountability</span>,
              &nbsp; and <span id='tidyS' className='highlight'>fair distribution of responsibilities</span> To
              accomplish this our system provides roommates with a medium to anonymously remind each other
              to do their chores and serves as a mediator by delegating chores according to preference. Plus, as an
              added byproduct of solving these problems our system also helps to
              &nbsp;<span id='tidyS' className='highlight'>maintaining positive roommate relationships</span> by
              reducing confrontation.</p>
          </div>
          <img src="https://imgur.com/wbFMMko.jpg" alt='Style guide'/>

          <div className='flex-grid4'>
            <div className='gifcontainer'>
              <img className='gif' src="https://imgur.com/moX71Zd.gif" alt='Animated chore prioritization gif'/>
              <img className='gif2' src="https://imgur.com/edgFAfh.gif" alt='Animated notification gif'/>
            </div>
            <div className='gifcontent'>
              <p>CHORE PRIORITIZATION</p>
              <h2>Just a friendly reminder...</h2>
              <p>Individuals can prioritize specific chores while anonymously reminding
                roommates to complete their own responsibilities.</p>
            </div>
          </div>

          <div className='flex-grid4 reverse'>
            <div className='gifcontainer'>
              <img src="https://imgur.com/W9Pcdhb.gif" alt='Animated chore preferences gif'/>
              <img src="https://imgur.com/MnckbSv.gif" alt='Animated onboarding gif'/>
            </div>
            <div className='gifcontent'>
              <p>CHORE PREFERENCE</p>
              <h2>Set your preferences!</h2>
              <p>Each roommate sets their personal chore preferences which tidy will use to
                delegate chores.</p>
            </div>
          </div>
        </div>

        <div id='reflection' className='process-section'>
          <div className='process-title'>
            <p>05 /</p>
            <h1>Reflection</h1>
          </div>
          <div className='process-text'>
            <h2>No one right method</h2>
            <p>Having gone through the user centered design process once before, I felt very
              confident and aware of what I needed to do in order to make this a successful project. However,
              I quickly realized that every designer has a slightly different design process.
              There is no one right method. Although most designers follow the same general order of research
              then design, the steps and deliverables in between may vary depending on individual needs. It's
              important for me to develop a big repertoire of skills so that I have options for how to approach
              different challenges that might arise in the future.</p>

            <p>In the case of this project, knowing how to curate a comprehensive
              IA was not a required deliverable, albeit absolutely necessary
              to help visualize the flow of interactions.</p>

            <p>Big boy shoutout to the fellas who made this project sucessful <span id='tidyS' className='highlight'><a href='https://swshin.me/' target='_open'><b>Seung Wong Shin</b></a></span> &nbsp;
              Johnathan Chen, John McMahon, and Kcee Landon.</p>
          </div>
        </div>

        <section id='otherproj'>
        <Link to='/becu'>
          <div id='previous' className='cta'>
            <div>
              <img src='images/becucardimg.png' alt='BECU Case Study'/>
            </div>
            <div>
              <p>PREVIOUS</p>
              <h3>BECU &mdash; Case Study</h3>
              <FaLongArrowAltLeft/>
            </div>
          </div>
        </Link>
        <Link to='/forumone'>
          <div id='next' className='cta'>
            <div>
              <img src='images/f1logo.png' alt='Forum One Internship'/>
            </div>
            <div>
              <p>UP NEXT</p>
              <h3>Forum One &mdash; Internship</h3>
              <FaLongArrowAltRight/>
            </div>
          </div>
        </Link>
      </section>
      </section>
    </div>
  );
}

export default Tidy;