import React from 'react';

function CardType2({
  image,
  altxt,
  title,
  type,
  projId,
}) {

  return (
    <div id={projId} className='card'>
      <div className='hero'>
        <img src={image} alt={altxt}/>
      </div>
      <div className='ct2-info'>
        <p>{type}</p>
        <h2>{title}</h2>
      </div>
    </div>
  );
}

export default CardType2;