import React from 'react';
import { Link } from 'react-router-dom';
// import { FiMenu} from 'react-icons/fi'
import { FaBehance, FaGithub, FaLinkedinIn } from "react-icons/fa";
import { IoIosMail, IoIosClose } from "react-icons/io";
import gsap from 'gsap';

function Nav() {

  function scrollTop() {
    window.scrollTo(0, 0);
  }

  // function highlight(e) {
  //   window.scrollTo(0, 0);
  //   let active = e.currentTarget;
  //   document.querySelector('.current').classList.remove('current');
  //   active.classList.add('current');
  // }

  if (window.innerWidth <= 768) {
    var prevScrollpos = window.pageYOffset;
    window.addEventListener('scroll', function() {
    var currentScrollPos = window.pageYOffset;
      if (prevScrollpos > currentScrollPos) {
        document.querySelector('.mobile').style.top = "0";
        // document.querySelector('.logo').style.top = "0";
      } else {
        document.querySelector('.mobile').style.top = "-80px";
        // document.querySelector('.logo').style.top = "-80px";
      }
      prevScrollpos = currentScrollPos;
    });
  }

  // function init() {
  //   let projects = document.querySelectorAll('.project');
  //   for (let i = 0; i < projects.length; i++) {
  //     projects[i].addEventListener('mouseover', changemenu);
  //   }
  // }

  // function changemenu(e) {
  //   let current = document.getElementById('color-expand');
  //   current.classList = 'expand default-color';
  //   let name = e.currentTarget;
  //   let cls = name.querySelector('#colorblock').className;
  //   document.getElementById('color-expand').classList.add(cls);
  // }

  function expand() {
    let pageWidth = window.innerWidth;
    if (pageWidth <= 450) {
      gsap.to('.expand', .6, {x: '-80vw', ease: 'power4.out', stagger: .2})
    } else {
      gsap.to('.expand', .6, {x: '-30vw', ease: 'power4.out', stagger: .2})
    }
    document.getElementById('menu').classList.add('hidden');
    document.getElementById('menu-btn').classList.add('hidden');
    document.getElementById('close-btn').classList.remove('hidden');
  }

  function close() {
    gsap.to('.expand', .6, {x: '0vw', ease: 'power4.out', stagger: .2})
    document.getElementById('menu').classList.remove('hidden');
    document.getElementById('menu-btn').classList.remove('hidden');
    document.getElementById('close-btn').classList.add('hidden');
  }

  return (
    <section id='navigation'>
      <nav className='web'>
        <Link to='/'>
          <img src='images/whitelogo.png' alt='logo'/>
        </Link>
        <ul>
          <Link className='link' to='/'>
            <li className='link-underline' onClick={scrollTop}>Home</li>
          </Link>
          <Link className='link' to='/about'>
            <li className='link-underline' onClick={scrollTop}>About</li>
          </Link>
            <li className='link-underline'>
              <a href='https://www.dropbox.com/s/f7kg7qn9czg73bo/Resume_Sep-30-2020.pdf?dl=0' target='_open'>
                  Resume</a></li>
          <Link className='link' to='/creative'>
            <li className='link-underline' onClick={scrollTop}>Creative</li>
          </Link>
        </ul>
        <section id='socials'>
          <a href='https://www.linkedin.com/in/brentmwong/' target='_open'><FaLinkedinIn/></a>
          <a href='https://www.behance.net/brentmwong' target='_open'><FaBehance/></a>
          <a href='https://github.com/brentmwong' target='_open'><FaGithub/></a>
          <a href='mailto:brent.m.wong@gmail.com' target='_open'><IoIosMail/></a>
        </section>
      </nav>

      <nav className='mobile'>
        <Link to='/'>
          <img src='images/whitelogo.png' alt='logo' className='logo'/>
        </Link>
        <section id='color-expand' className='expand default-color'>
          <div id='menu-expand' className='expand'>
            <ul>
              <Link to='/'>
                <li className='link-underline' onClick={close}>Home</li>
              </Link>
              <Link to='/about'>
                <li className='link-underline' onClick={close}>About</li>
              </Link>
              <a href='https://www.dropbox.com/s/f7kg7qn9czg73bo/Resume_Sep-30-2020.pdf?dl=0' target="_open">
                <li className='link-underline'>Resume</li>
              </a>
              <Link to='/creative'>
                <li className='link-underline' onClick={close}>Creative</li>
              </Link>
            </ul>
            <section id='menu-socials'>
              <a href='https://www.linkedin.com/in/brentmwong/' target='_open'><FaLinkedinIn/></a>
              <a href='https://www.behance.net/brentmwong' target='_open'><FaBehance/></a>
              <a href='https://github.com/brentmwong' target='_open'><FaGithub/></a>
              <a href='mailto:brent.m.wong@gmail.com' target='_open'><IoIosMail/></a>
            </section>
          </div>
            <div id='hamburger'>
              <div id='menu' onClick={expand}>
                <div id='menu-btn' onClick={expand}></div>
              </div>
              <IoIosClose id='close-btn' className='hidden' onClick={close}/>
            </div>
        </section>
      </nav>
    </section>
  );
}

export default Nav;