import React from 'react';
// import Tab from '../comp/projectab';
import Card from '../comp/projectcard';
import CardT2 from '../comp/cardtype2'
import { FaArrowDown } from "react-icons/fa";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Link } from 'react-router-dom';
import Rellax from 'react-rellax';
import gsap from 'gsap';

function Home() {

  window.addEventListener('load', init);
  // window.addEventListener('resize', () => {
  //   window.location.reload(true);
  // });

function init() {
  let pageWidth = window.innerWidth;
  let t1 = gsap.timeline({Delay: 0});
  let t2 = gsap.timeline({Delay: 5});
  t1.from('#intro', {left: '-50vw', ease: 'power1.out', duration: .6});
  t1.from('#image-container', {x: '0', ease: 'power1.out', duration: 0});
  t1.from('#revealer', {width: '101%', ease: 'power1.out', duration: .6});
  if (pageWidth <= 450) {
    t2.from('#down', {right: '-150%', ease: 'power2.out', duration: .6});
    t2.from('#snippet', {right: '-150%', ease: 'power2.out', duration: .6});
    t2.from('#text-revealer', {y: '0', height: '100%', ease: 'power1.out', duration: 1});
  } else {
    t2.from('#down', {right: '-150%', ease: 'power2.out', duration: .6});
    t2.from('#snippet', {right: '-150%', ease: 'power2.out', duration: .6});
    t2.from('#text-revealer', {y: '0', height: '100%', ease: 'power1.out', duration: 1});
  }
}

function change(e) {
  gsap.fromTo('#text-revealer', {y: '0', height: '100%', ease: 'power1.out', duration: 1.5},
    {y: '0', height: 0, ease: 'power1.out', duration: 1.5});
  let chosen = e.currentTarget;
  let active = document.querySelector('#intro .intro-active');
  active.classList.remove('intro-active');
  chosen.classList.add('intro-active');
  console.log(chosen.textContent);
  let snip = document.querySelector('#snippet h3');
  if (chosen.textContent === '02UX Designer') {
    snip.textContent = '02. I strive for accessibility'
  } else if (chosen.textContent === '03Developer') {
    snip.textContent = '03. I make my ideas tangible'
  } else {
    snip.textContent = '01. I create elegant experiences'
  }
}

function next() {
  let focusedproj = document.querySelector('.scrolling-wrapper');
  let card = document.querySelector('.card');
  focusedproj.scrollLeft += card.offsetWidth + 50;
}

function back() {
  let focusedproj = document.querySelector('.scrolling-wrapper');
  let card = document.querySelector('.card');
  focusedproj.scrollLeft -= card.offsetWidth + 50;
}


  return (
    <div className='App'>
      <section id='landing' data-rellax-speed='4'>
        <Rellax id='intro' speed={5}>
          <p className='label'>Hi I'm Brent</p>
          <h1 onClick={change} className='intro-active'><span>01</span>Product Designer</h1>
          <h1 onClick={change} className=''><span>02</span>UX Designer</h1>
          <h1 onClick={change} className=''><span>03</span>Developer</h1>
          {/* <div className='line'/> */}
        </Rellax>
        <section id='image-container'>
          <div id='revealer'/>
          <div id='overflow'>
            <img src='images/grad.jpg' alt='Photo of Brent' className='kenburns-right'/>
          </div>
          <section id='block'>
            <Rellax id='snippet' speed={3}>
              <div id='text-revealer'/>
              <h3>01. I create elegant experiences</h3>
            </Rellax>
            <Rellax id='down' speed={2}>
              <FaArrowDown className='heartbeat'/>
            </Rellax>
          </section>
        </section>
      </section>

      <main>
        {/* <Tab/> */}
        <section id='projects'>
          <Rellax speed={-1}>
            <h3>Featured Work</h3>
          </Rellax>
          <Link to='/becu'><Card
            idNum='1'
            name='BECU'
            date='Apr &mdash; 2020'
            type='Product Design &nbsp;&bull;&nbsp; UX Design'
            description='Senior capstone aimed at encouraging young adults to be
              more proactive about their finances through social engagement.'
            image='images/becumockup.png'
            alt='BECU Case Study'
            bg='images/becubg.png'
            fontColor='white'
            blockcolor='becuB'
            cName='becu'
            worktype='Case Study'
          /></Link>
          <Link to='/tidy'><Card
            idNum='2'
            name='Tidy'
            date='Jan &mdash; 2020'
            type='Product Design &nbsp;&bull;&nbsp; UX Design'
            description='Helping roommates manage shared living spaces by
              promoting chore accountability and fair distribution of responsibilities.'
            image='images/tidymockup.png'
            alt='Tidy Case Study'
            bg= 'images/tidybg.png'
            fontColor='white'
            blockcolor='tidyG'
            cName='tidy'
            worktype='Case Study'
          /></Link>
          <Link to='/forumone'><Card
            idNum='3'
            name='Forum One'
            date='Jun &mdash; 2019'
            type='UX Design Internship'
            description='6 month internship with Forum One where I collaborated with UX Designers on various
              client side deliverables.'
            image='images/f1mockup.png'
            alt='Forum One Internship'
            bg='images/f1bg.png'
            fontColor='White'
            blockcolor='f1R'
            cName='f1'
            worktype='Internship'
          /></Link>
          <Link to='/yoglo'><Card
            idNum='4'
            name='Yoglo'
            date='Sep &mdash; 2018'
            type='Product Design &nbsp;&bull;&nbsp; UX Design'
            description='My introduction to User Centered Design. Worked with yoga practitioners
              to create a digital experience that caters to any skill level.'
            image='images/yoglomockup.png'
            alt='Yoglo Case Study'
            bg='images/yoglobg.png'
            fontColor='white'
            blockcolor='yogloB'
            cName='yoglo'
            worktype='Case Study'
          /></Link>
          <h3>Other Projects</h3>
          <section className='scrolling-wrapper' data-aos='fade-right'>
            <Link to='/airbnb'><CardT2
              image='images/airbnb.png'
              altxt='Airbnb Creative Jam'
              title='Journey &mdash; Airbnb Creative Jam'
              type='Product Design'
              projId='airbnb'
            /></Link>
            <Link to='/set'><CardT2
              image='images/set.png'
              altxt='Set Card Game'
              title='Set &mdash; Online Card Game'
              type='Web Development'
              projId='set'
            /></Link>
            <Link to='/pokedex'><CardT2
              image='images/pokemon.png'
              altxt='Pokemon Battle Simulator'
              title='Pokemon Battle Simulator'
              type='Web Development'
              projId='pokemon'
            /></Link>
            {/* <CardT2
              image='images/seattle.png'
              altxt=''
              title='Seafair vs. Aquarium'
              type='Visual communication'
              projId='poster'
            /> */}
          </section>
          <div className='button-container'>
            <button id='back' onClick={back}><IoIosArrowBack/></button>
            <button id='forward' onClick={next}><IoIosArrowForward/></button>
          </div>
        </section>
      </main>

      <footer data-aos='slide-up' anchor-placement='center-center' data-aos-duration="2000">
        <div>
          <h1>Need a hand? I got two!</h1>
        </div>
        <div id='foot-container'>
          <div>
            <h3>Start a conversation</h3>
            <p>brent.m.wong@gmail.com</p>
            <p>+1 206 466 8927</p>
          </div>
          <div>
            <h3>Follow</h3>
            <p>LinkedIn</p>
            <p>Behance</p>
          </div>
        </div>
        <p id='copy'>&copy; 2020 Brent Wong. Coded With React</p>
      </footer>
    </div>
  );
}

export default Home;
