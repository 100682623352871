import React from 'react';
import gsap from 'gsap';
import Rellax from 'react-rellax';
import { FiChevronLeft } from "react-icons/fi";
import { FaLongArrowAltLeft, FaLongArrowAltRight} from 'react-icons/fa'
import { Link } from 'react-router-dom';

function Yoglo() {
  window.scrollTo(0, 0);
  setTimeout(function() {
    animate();
  }, 0);
  // window.addEventListener('load', animate);
  window.addEventListener('scroll', detailAnimate);
  // window.addEventListener('resize', () => {
  //   window.location.reload(true);
  // });

  async function animate() {
    document.querySelector('#proj-cover img').classList.remove('hidden');
    document.querySelector('.backbtn').classList.remove('hidden');
    await document.querySelector('#proj-cover').classList.add('yoglohero');
    let t1 = gsap.timeline();
    t1.from('#proj-overview', {y: 150, opacity: 0, duration: .6, ease: 'power1.out'});
    t1.from('.proj-revealer', {width: '101%', duration: .5, ease: 'power1.out', delay: .3});
    t1.from('#proj-cover img', {y: -200, opacity: 0, duration: 1, ease: "elastic.out(1, 0.6)"});
    t1.from('.backbtn', {x: -100, opacity: 0, duration: .3, ease: 'power1.out'});
  }

  function detailAnimate() {
    let t2 = gsap.timeline();
    t2.from('.detail', {y: 50, opacity: 0, duration: 1, stagger: .4, delay: .3});
    window.removeEventListener('scroll', detailAnimate);
  }

  return (
    <div className='App'>
      <section id='proj-intro'>
        <Link to='/'>
          <button className='backbtn hidden'><FiChevronLeft/>Home</button>
        </Link>
        <div id='proj-cover'>
          <div className='proj-revealer'/>
          <Rellax speed={-3}>
            <img className='hidden' src='images/yoglogroupmockup.png' alt ='yoglo case study project'/>
          </Rellax>
        </div>
      </section>

      <section id='proj-overview'>
          <div id='proj-name'>
            <div>
              <h2 id='number'>04</h2>
              <p>Case Study</p>
              <h1>Yoglo</h1>
            </div>
          </div>
          <div id='proj-details'>
            <div className='detail'>
              <p>ROLE</p>
              <p>Product Designer</p>
            </div>
            <div className='detail'>
              <p>DURATION</p>
              <p>10 Weeks</p>
            </div>
            <div className='detail'>
              <p>DATE</p>
              <p>Sep, 2018</p>
            </div>
          </div>
        </section>

      <section className='content'>
        <div id='introduction' className='process-section'>
          <div className='process-title'>
            <p>00 /</p>
            <h1>Introduction</h1>
          </div>
          <div className='process-text'>
            <h2>Context</h2>
            <p>In the past 6 years, Seattle has seen a 700% increase in the yoga community.
              With such a huge inflation, my team felt that this was the perfect opportunity to
              apply our knowledge of the user-centered design process to new yoga practitioners.</p>

            <p>Yoglo is a smart-mat and app combination designed with the new student in mind.
              &nbsp;<span id='yogloS' className='highlight'>The aim is to provide the user with information
              about correct hand and feet placement through an ambient display of lights.</span></p>
          </div>
        </div>

        <div id='research' className='process-section'>
          <div className='process-title'>
            <p>01 /</p>
            <h1>Research</h1>
          </div>
          <div className='process-text'>
            <p>We managed to schedule interviews with 1 instructor,
              2 experienced "yogis", and 2 beginners. Each interview gave us interesting
              insights into some of the pain points that exist within the yoga community.</p>

            <h2>Pain Points</h2>
            <ol>
              <li>Carrying big yoga mat and other equipment around can be <strong>troublesome.</strong></li>
              <li>Going to <strong>studio can be intimidating</strong> because of varying skill levels.</li>
              <li>Practicing with a partner makes the experience <strong>more enjoyable</strong>.</li>
              <li>Hard to <strong>remember and keep track</strong> all the different poses.</li>
              <li>Want to make sure <strong>posture is correct</strong>.</li>
            </ol>

            <h2>Personas</h2>
            <p>Our team put together two personas to better scope our target audience and empathize with
              their pain points, goals, and desires.</p>
          </div>
          <img src="images/yoglopersona1.png" alt='Sunny Kim Persona'/>
          <img src="images/yoglopersona2.png" alt='Sara Michaels Persona'/>
          <div className='process-text'>
            <h2>Journey Map</h2>
            <p>We then created a user journey map for our yoga practitioner Sara,
              helping us contextualize her actions, thoughts, emotions throughout her yoga
              experience. This helped identify touch points that we could potentially design for.</p>
          </div>
          <img src="images/yoglojourneymap.png" alt='Sara Michaels Journey Map'/>
        </div>

        <div id='ideation' className='process-section'>
          <div className='process-title'>
            <p>02 /</p>
            <h1>Ideation</h1>
          </div>
          <div className='process-text'>
            <h2>Problem Statement</h2>
            <p id='yogloS' className='statement'>How might we use technology to improve yoga pose knowledge, while allowing the
              user to maintain a mindful state?</p>
          </div>

          <video autoPlay muted loop id="projectVideo">
            <source src="images/yoglomindmap.mp4" type="video/mp4"/>
          </video>

          <div className='process-text'>
            <p>Before we could start the ideation process our team identified design requirements that
              addressed our problem statement. Our solution should... </p>

            <h2>Design Requirements</h2>
            <ol>
              <li>Help <strong>communicate correct body position</strong> for various poses.</li>
              <li>Help user connect with yoga community and <strong>build relationships.</strong></li>
              <li><strong>Promote mindfulness</strong> and body awareness.</li>
              <li>Provide feedback to <strong>support learning</strong> and growth.</li>
            </ol>

            <h2>Storyboarding</h2>
            <p>With a clear set of guidelines to design around we could start generating various
              solutions in the form of storyboards.</p>
          </div>
          <img src="https://i.imgur.com/oGgiJAk.jpg" alt='storyboard 1'/>
          <img src="https://i.imgur.com/QGtUZe4.jpg" alt='storyboard 2'/>
          <img src="https://i.imgur.com/zc9yXIB.png" alt='storyboard 3'/>
          <div className='process-text'>
            <p>For each of our storyboards we meticulously considered the feasibility and functionality
              of our solutions. We came to the conclusion that we wanted to create a smart mat that ambiently
              (using lights) conveyed to the user how to mimic poses correctly. We also realized we would
              have to supplement our mat with a app in order for the mat to know what pose to guide the user through.</p>

            <h2>Information Architecture</h2>
            <p>To better understand how we wanted our mat + app combo to work we created an
              information architecture to map out the different pathways users could interact
              with our interfaces.</p>

            <h2>Takeaway</h2>
            <p>Users should have an option to create or follow predetermined yoga
              flows depending on whether they are practicing at home or at a studio. Users can then
              download these flows onto the mat, which then guides them through the flow.</p>
          </div>
          <img src="https://i.imgur.com/5OgY3Ak.png" alt='Information Architecture' className='white'/>
        </div>

        <div id='prototype' className='process-section'>
          <div className='process-title'>
            <p>03 /</p>
            <h1>Prototype</h1>
          </div>
          <div className='process-text'>
            <p>Our group decided to create low-fidelity paper prototypes because they're cheap and easy to
              modify. Our prototypes were based around three tasks that we wanted users to complete:</p>
            <ol>
              <li>Create a custom yoga flow from home.</li>
              <li>Pick a predetermined class flow from a studio.</li>
              <li>Follow the yoga flow displayed by the mat.</li>
            </ol>

            <h2>Usability Testing</h2>
            <p> After conducting some usability tests, our users were happy with the functionality of the app,
              but concerned about the functionality of the mat. They were unsure when the mat would transition
              into the next pose and also thought the lights/display might become a distraction as they become more
              familiar with the poses. Our users suggested:</p>
            <ol>
              <li>Allow practitioners to <strong>set a time limit</strong> for each yoga pose.</li>
              <li>Add a <strong>slide bar</strong> on the mat to allow users to customize the light intensity.</li>
              <li>Add a <strong>switch button</strong> to turn off and on the visual display on the mat.</li>
            </ol>
          </div>
          <img src='images/yoglomatprototype.jpg' alt='mat usability test with participant'/>
          <div className='flex-grid'>
            <img src="https://i.imgur.com/6Z1uAXT.jpg" alt='prototype 1'/>
            <img src="https://i.imgur.com/BSjULRb.jpg" alt='prototype 2'/>
            <img src="https://i.imgur.com/ubBspOr.jpg" alt='prototype 3'/>
            <img src="https://i.imgur.com/QgYSemC.jpg" alt='prototype 4'/>
            <img src="images/yogloprototype5.png" alt='prototype 5'/>
            <img src="images/yogloprototype6.png" alt='prototype 6'/>
          </div>
        </div>

        <div id='design' className='process-section'>
          <div className='process-title'>
            <p>04 /</p>
            <h1>Design</h1>
          </div>
          <div className='process-text'>
            <h2>Wireframes/Schematics</h2>
            <p>To begin the design process our team developed wireframes and schematics to better
              represent the actions a user can make while using our app and mat. The two main pathways
              a user can follow are: creating custom flows and selecting a studio class.</p>
            <a className='pdflink' href='https://drive.google.com/file/d/19CyDAuHramuSawzkf31HOWpa1igsYTVa/view?usp=sharing' target='_open'>
              <span id='yogloS' className='highlight'>View all wireframes here</span>
            </a>
          </div>
          <img src="https://i.imgur.com/558peb7.png" alt='App Flow Map'/>
          <img src="https://i.imgur.com/8zxiE5P.jpg" alt='Mat Schematic'/>
          <img src="https://i.imgur.com/RBlnIQr.jpg" alt='Mat Features'/>
          <div className='process-text'>
            <h2>High Fidelity Mockups</h2>
            <p>Following several rounds of critique sessions, the final step in our design process
              culminated in three high-fidelity mockups of our app and mat. These design choices
              reflect feedback given to us throughout every phase of our research, ideation, and
              usability testing.</p>

            <h2>Mat Design Choices</h2>
            <p>We designed a mat that used an ambient display of lights,
              to let the user know where to place their hands and feet during a specific pose.</p>
            <ol>
              <li><b>LED lights</b> (to help convey correct limb placement).</li>
              <li><b>Display screen</b> (to help user know what pose looks like).</li>
              <li><b>Switch</b> (allow user to turn on and off display).</li>
              <li><b>Slide Bar</b> (allow user to adjust light intensity).</li>
            </ol>
          </div>
          <img src="https://i.imgur.com/B6i4wTM.png" alt='Mat Mockup'/>
          <div className='process-text'>
            <h2>App Design Choices</h2>
            <p>The <span id='yogloS' className='highlight'>studio interface</span> allows users to search for specific classes offered at a studio
              of their choice. Creating a more personal experience, the user is able to find a
              class that fits their interest and skill level.</p>
            <ol>
              <li><b>Search Filter</b> (Allows user to search by class or instructor).</li>
              <li><b>Community Tab</b> (Lets user interact with that specific yoga community).</li>
            </ol>
            <p>The <span id='yogloS' className='highlight'>progress report</span> interface, highlights some of the user's stats.
              The main feature of this page is the balance tab, another alternative
              form of feedback that lets the user know how well they were able to maintain a posture.</p>
            <ol>
              <li><b>Past Flows Tab</b> (Allows user to compare and contrast their progress).</li>
              <li><b>Swivel Circle</b> (Helps convey to user that they must swipe in order to rotate between poses).</li>
            </ol>
          </div>
          <div className='flex-grid2'>
            <img src="images/yoglostudiointerface.png" alt='Studio Screen Mockup'/>
            <img src="images/yogloprogressinterface.png" alt='Progress Report Mockup'/>
          </div>
        </div>

        <div id='redesign' className='process-section'>
          <div className='process-title'>
            <p>05 /</p>
            <h1>Redesign</h1>
          </div>
          <div className='process-text'>
            <p>Simply put, this entire project was born under a time crunch. As a result, I was left a bit
              underwhelmed with our final product presenting me with an opportunity to improve the design
              and overall experience.</p>

            <p>I decided to completely revamp the visual language with a greater emphasis on <span id='yogloS' className='highlight'>hierarchy and designs
              that better match preexisting mental models.</span></p>
          </div>
          <div className='flex-grid3'>
            <img src="https://imgur.com/rzIlFJ2.png" alt='Redesigned home page'/>
            <img src="https://imgur.com/ZEXD1BM.jpg" alt='Redesigned studio page'/>
            <img src="https://imgur.com/qM6U3Ya.jpg" alt='Class detail page'/>
            <img src="https://imgur.com/Fp0w4lD.jpg" alt='Redesigned progress report page'/>
          </div>
          <img src="https://imgur.com/MR7jKTQ.png" alt='App Flow Map'/>
          <img src="https://imgur.com/NTDeJ51.png" alt='Mat Schematic'/>
          <img src="https://imgur.com/jXVNIHi.png" alt='Mat Features'/>
          <img src="https://imgur.com/goNT5EN.png" alt='Mat Schematic'/>
        </div>

        <div id='reflection' className='process-section'>
          <div className='process-title'>
            <p>06 /</p>
            <h1>Reflection</h1>
          </div>
          <div className='process-text'>
            <p>Although constrained to 10 weeks, I learned a lot about myself as a designer and
              about the user-centered design process as a whole.</p>

            <h2>Design is grounded in research</h2>
            <p>Early on in the project I was eager to design. However, I quickly
              realized that design without context is meaningless and designing for everyone is the samething
              as designing for no one. It's important to scope your target audience and gain insights from
              their experiences. "The user knows best."</p>

            <h2>Eliminate design biases</h2>
            <p>If there's one thing I'll takeaway from this experience it's to eliminate my design
              biases so that we don't design around our own needs. This is one reason why my group
              decided to design for yoga practitioners. None of us had any prior knowledge with yoga,
              hindering our ability to make assumptions about our users' pain points.</p>

            <h2>Perfectly imperfect</h2>
            <p>Nothing about this project is perfect, and that's ok! What's important is that
              I reflect on where we went astray and how I can better adjust to prevent it from
              happening in my next project. One thing I wish we could've explored more of was the
              the community aspect of yoga. The yoga instructor we talked to mentioned that,
              "even though the yoga community is growing, it is still homogeneous and lacks
              diversity." I think there's an interesting opportunity to expand the yoga demographic.</p>

            <p>Special thanks to my teammates <span id='yogloS' className='highlight'><a href='https://www.saraphinawang.com/' target='_open'><b>Saraphina Wang</b></a></span> and &nbsp;
            <span id='yogloS' className='highlight'><a href='https://www.ivykehoe.com/' target='_open'><b>Ivy Kehoe</b></a></span>.</p>
          </div>
        </div>

        <section id='otherproj'>
        <Link to='/forumone'>
          <div id='previous' className='cta'>
            <div>
              <img src='images/f1logo.png' alt='Forum One Internship'/>
            </div>
            <div>
              <p>PREVIOUS</p>
              <h3>Forum One &mdash; Internship</h3>
              <FaLongArrowAltLeft/>
            </div>
          </div>
        </Link>
        <Link to='/airbnb'>
          <div id='next' className='cta'>
            <div>
              <img src='images/airbnb.png' alt='Airbnb Creative Jam'/>
            </div>
            <div>
              <p>UP NEXT</p>
              <h3>Airbnb &mdash; Creative Jam</h3>
              <FaLongArrowAltRight/>
            </div>
          </div>
        </Link>
      </section>
      </section>
    </div>
  );
}

export default Yoglo;