import React from 'react';
import Rellax from 'react-rellax';

const URL= "https://img.icons8.com/color/96/000000/"

function customCursor(e) {
  let cur = e.currentTarget;
  console.log(cur);
  let newurl = URL;
  if (cur.id === 'html') {
    newurl = URL + 'html-5.png';
    cur.style.cursor = 'url(' + newurl + '), auto';
  } else if (cur.id === 'css') {
    newurl = URL + 'css3.png';
    cur.style.cursor = 'url(' + newurl + '), auto';
  } else if (cur.id === 'js') {
    newurl = URL + 'javascript-logo-1.png';
    cur.style.cursor = 'url(' + newurl + '), auto';
  } else if (cur.id === 'react') {
    cur.style.cursor = 'url(https://img.icons8.com/nolan/96/react-native.png), auto';
  } else if (cur.id === 'figma') {
    cur.style.cursor = 'url(https://www.vectorlogo.zone/logos/figma/figma-icon.svg), auto';
  } else if (cur.id === 'sketch') {
    cur.style.cursor = 'url(https://www.vectorlogo.zone/logos/sketchapp/sketchapp-icon.svg), auto';
  } else if (cur.id === 'xd') {
    newurl = URL + 'adobe-xd.png';
    cur.style.cursor = 'url(' + newurl + '), auto';
  } else if (cur.id === 'cloud') {
    cur.style.cursor = 'url(https://img.icons8.com/fluent/96/000000/adobe-creative-cloud.png), auto';
  }
}

function About() {

  return (
    <div className='App'>
      <section id='splash'>
        <Rellax className='image-container' speed={-4}>
          <div className='mask'>
            <img src='https://imgur.com/iytgqOv.jpg' alt='Image of Brent Wong'></img>
            <img src='https://imgur.com/sFu6BOu.png' alt='Image of Brent Wong'></img>
            <video autoPlay muted id="myVideo">
              <source src="images/colorsplash.mp4" type="video/mp4"/>
            </video>
          </div>
          <Rellax className='rellax' data-rellax-speed={-2}>
            <h1>Brent Wong</h1>
          </Rellax>
        </Rellax>
      </section>

      <section id='main-bio'>
        <div className='main-bio-container'>
          <div id='introduction' className='process-section'>
            <div className='process-title'>
              <p>Who /</p>
              <h1>My Story</h1>
            </div>
            <div className='process-text'>
              <p>Hi, I'm Brent! I'm a young, ambitious designer/developer eager to expand my
                skillsets and share my knowledge!
              </p>

              <p>I recently graduated from the University of Washington with a Bachelor's degree
                in <strong>Human Centered Design & Engineering</strong> with a focus in Human Computer
                interaction.
              </p>

              <p>Born into a modest working class family, I always found myself surrounded by creative
                individuals. I come from a long lineage of graphic designers, architects, photographers,
                and engineers like my dad; who prides himself on being a "handy man" and who has continually
                shown me what it's like to be resourceful yet innovative.
              </p>

              <p>In a few years I hope to develop systems based on human needs,
                and to be able to share this kind of technology with those living in low resource
                populations.
              </p>
            </div>
          </div>
          <div className='process-title'>
            <p>What /</p>
            <h1>My Skills</h1>
          </div>
          <section id='skills'>
            <div className='grid-section'>
              <p id='html' onMouseOver={customCursor}>HTML5</p>
            </div>
            <div className='grid-section'>
              <p id='css' onMouseOver={customCursor}>CSS</p>
            </div>
            <div className='grid-section'>
              <p id='js' onMouseOver={customCursor}>JavaScript</p>
            </div>
            <div className='grid-section'>
              <p id='react' onMouseOver={customCursor}>React</p>
            </div>
            <div className='grid-section'>
              <p id='figma' onMouseOver={customCursor}>Figma</p>
            </div>
            <div className='grid-section'>
              <p id='sketch' onMouseOver={customCursor}>Sketch App</p>
            </div>
            <div className='grid-section'>
              <p id='xd' onMouseOver={customCursor}>Adobe Xd</p>
            </div>
            <div className='grid-section'>
              <p id='cloud' onMouseOver={customCursor}>Adobe CC</p>
            </div>
          </section>
        </div>
      </section>
    </div>
  );
}

export default About;