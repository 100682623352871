import React from 'react';
import gsap from 'gsap';
import Rellax from 'react-rellax';
import { FiChevronLeft } from "react-icons/fi";
import Particles from 'react-particles-js';
import { FaLongArrowAltLeft, FaLongArrowAltRight} from 'react-icons/fa'
import { Link } from 'react-router-dom';

function Creative() {
  window.scrollTo(0, 0);
  setTimeout(function() {
    animate();
  }, 0);
  // window.addEventListener('load', animate);
  window.addEventListener('scroll', detailAnimate);
  // window.addEventListener('resize', () => {
  //   window.location.reload(true);
  // });

  async function animate() {
    document.querySelector('#vault-intro h1').classList.remove('hidden');
    await document.querySelector('#vault-intro').classList.add('vaulthero');
    let t1 = gsap.timeline();
    t1.from('#proj-overview', {y: 150, opacity: 0, duration: .6, ease: 'power1.out'});
    t1.from('.proj-revealer', {width: '101%', duration: .5, ease: 'power1.out', delay: .3});
    t1.from('#vault-intro h1', {y: -200, opacity: 0, duration: 1, ease: "elastic.out(1, 0.6)"});
  }

  function detailAnimate() {
    let t2 = gsap.timeline();
    t2.from('.detail', {y: 50, opacity: 0, duration: 1, stagger: .4, delay: .3});
    window.removeEventListener('scroll', detailAnimate);
  }

return (
    <div className='App'>
      <section id='vault-intro'>
        <Rellax className='vaultheader' speed={-3}>
          <h1 className='hidden'>Creative Expression</h1>
        </Rellax>
        <Particles
          params={{
            "particles": {
              "number": {
                "value": 200,
                "density": {
                  "enable": true,
                  "value_area": 2000
                }
              },
              "color": {
                "value": "#5498fd"
              },
              "shape": {
                "type": "circle",
                "stroke": {
                  "width": 0,
                  "color": "#000000"
                },
                "polygon": {
                  "nb_sides": 5
                },
                "image": {
                  "src": "img/github.svg",
                  "width": 100,
                  "height": 100
                }
              },
              "opacity": {
                "value": 0.5,
                "random": true,
                "anim": {
                  "enable": false,
                  "speed": 1,
                  "opacity_min": 0.1,
                  "sync": false
                }
              },
              "size": {
                "value": 8,
                "random": true,
                "anim": {
                  "enable": false,
                  "speed": 40,
                  "size_min": 0.1,
                  "sync": false
                }
              },
              "line_linked": {
                "enable": false,
                "distance": 500,
                "color": "#ffffff",
                "opacity": 0.4,
                "width": 2
              },
              "move": {
                "enable": true,
                "speed": 2,
                "direction": "bottom",
                "random": false,
                "straight": false,
                "out_mode": "out",
                "bounce": false,
                "attract": {
                  "enable": false,
                  "rotateX": 600,
                  "rotateY": 1200
                }
              }
            },
            "interactivity": {
              "detect_on": "canvas",
              "events": {
                "onhover": {
                  "enable": true,
                  "mode": "repulse"
                },
                "onclick": {
                  "enable": true,
                  "mode": "repulse"
                },
                "resize": true
              },
              "modes": {
                "grab": {
                  "distance": 400,
                  "line_linked": {
                    "opacity": 0.5
                  }
                },
                "bubble": {
                  "distance": 400,
                  "size": 4,
                  "duration": 0.3,
                  "opacity": 1,
                  "speed": 3
                },
                "repulse": {
                  "distance": 100,
                  "duration": 0.4
                },
                "push": {
                  "particles_nb": 4
                },
                "remove": {
                  "particles_nb": 2
                }
              }
            },
            "retina_detect": true
        }}>
        </Particles>
      </section>

      <section className='content'>
        <div id='introduction' className='process-section'>
          <div className='process-title'>
            <p>01 /</p>
            <h1>UI Design</h1>
          </div>

          <section id='gallery'>
            <video autoPlay muted loop className="webuiVideo">
              <source src="images/gamingsite.mp4" type="video/mp4"/>
            </video>
            <p>Desktop version for a Gaming site idea.</p>
          </section>

          <div className='process-title'>
            <p>02 /</p>
            <h1>Graphic Design</h1>
          </div>
          <div className='process-text'>

          </div>
          <section id='gallery'>
            <img data-aos='fade-up' src="https://imgur.com/nPSL4DE.png" alt='Kobe silhouette'/>
            <img data-aos='fade-up' src="https://imgur.com/sjqgsK7.png" alt='Bruce lee silhouette'/>
            <img data-aos='fade-up' src="https://imgur.com/ewiwHkJ.png" alt='Michael jackson silhouette'/>
            <img data-aos='fade-up' src="https://imgur.com/1Q4WpSB.png" alt='Beyonce silhouette'/>
            <img data-aos='fade-up' src="https://imgur.com/pEVmHmj.png" alt='Muhammad ali silhouette'/>
            <img data-aos='fade-up' src="https://imgur.com/6hclgS0.png" alt='Seafair Poster'/>
            <img data-aos='fade-up' src="https://imgur.com/mMs58KG.png" alt='Aquarium Poster'/>
          </section>
        </div>
      </section>
    </div>
  );
}

export default Creative;