import React from 'react';
import gsap from 'gsap';
import Rellax from 'react-rellax';
import { FiChevronLeft } from "react-icons/fi";
import { FaLongArrowAltLeft, FaLongArrowAltRight} from 'react-icons/fa'
import { Link } from 'react-router-dom';

function Dex() {
  window.scrollTo(0, 0);
  setTimeout(function() {
    animate();
  }, 0);
  // window.addEventListener('load', animate);
  window.addEventListener('scroll', detailAnimate);
  // window.addEventListener('resize', () => {
  //   window.location.reload(true);
  // });

  function animate() {
    document.querySelector('#proj-cover img').classList.remove('hidden');
    document.querySelector('.backbtn').classList.remove('hidden');
    document.querySelector('#proj-cover').classList.add('pokedexhero');
    let t1 = gsap.timeline();
    t1.from('#proj-overview', {y: 150, opacity: 0, duration: .6, ease: 'power1.out'});
    t1.from('.proj-revealer', {width: '101%', duration: .5, ease: 'power1.out', delay: .3});
    t1.from('#proj-cover img', {y: -200, opacity: 0, duration: 1, ease: "elastic.out(1, 0.6)"});
    t1.from('.backbtn', {x: -100, opacity: 0, duration: .3, ease: 'power1.out'});
  }

  function detailAnimate() {
    let t2 = gsap.timeline();
    t2.from('.detail', {y: 50, opacity: 0, duration: 1, stagger: .4, delay: .3});
    window.removeEventListener('scroll', detailAnimate);
  }

return (
    <div className='App'>
      <section id='proj-intro'>
        <Link to='/'>
          <button className='backbtn hidden'><FiChevronLeft/>Home</button>
        </Link>
        <div id='proj-cover'>
          <div className='proj-revealer'/>
          <Rellax speed={-3}>
            <img className='hidden' src='images/pokeball.png' alt ='pokeball'/>
          </Rellax>
        </div>
      </section>

      <section id='proj-overview'>
          <div id='proj-name'>
            <div>
              <h2 id='number'>07</h2>
              <p>Web Development</p>
              <h1>Pokedex</h1>
            </div>
          </div>
          <div id='proj-details'>
            <div className='detail'>
              <p>ROLE</p>
              <p>Developer</p>
            </div>
            <div className='detail'>
              <p>DURATION</p>
              <p>1 Week</p>
            </div>
            <div className='detail'>
              <p>DATE</p>
              <p>May, 2020</p>
            </div>
          </div>
        </section>

      <section className='content'>
        <div id='introduction' className='process-section'>
          <div className='process-title'>
            <p>00 /</p>
            <h1>Introduction</h1>
          </div>
          <video autoPlay muted loop id="projectVideo">
            <source src="images/pokedexvid.mov" type="video/mp4"/>
          </video>
          <div className='process-text'>
            <p>This class project introduced me to Web API's. For this assignment my job was to retrieve
              data from various API endpoints and use it to populate the Pokedex. I was also responsible
              for developing the game behavior.
            </p>
            <a href='https://pokemonsim.web.app/' id='defaultS' className='highlight' target='_open'>
              Visit Website</a>
          </div>
        </div>

        <div id='role' className='process-section'>
          <div className='process-title'>
            <p>01 /</p>
            <h1>Challenges</h1>
          </div>
          <div className='process-text'>
            <p>One problem I struggled with during this assignment was keeping track of the pokemon the
              player encounters and making sure it populates the pokedex only when they defeat it.</p>
          </div>
        </div>

        <div id='work' className='process-section'>
          <div className='process-title'>
            <p>02 /</p>
            <h1>Reflection</h1>
          </div>
          <div className='process-text'>
            <p>I think the biggest skill I gained from this assignment was the ability to read and
              comprehend API documentation. It's important to identify what type of data you're working
              with (JSON/Text) and the parameters required to make a get/post request for various endpoints.
            </p>
          </div>
        </div>

        <section id='otherproj'>
        <Link to='/set'>
          <div id='previous' className='cta'>
            <div>
              <img src='images/set.png' alt='Set Online Card Game'/>
            </div>
            <div>
              <p>PREVIOUS</p>
              <h3>Set &mdash; Card Game</h3>
              <FaLongArrowAltLeft/>
            </div>
          </div>
        </Link>
        <Link to='/becu'>
          <div id='next' className='cta'>
            <div>
              <img src='images/becucardimg.png' alt='BECU case study'/>
            </div>
            <div>
              <p>UP NEXT</p>
              <h3>BECU &mdash; Case Study</h3>
              <FaLongArrowAltRight/>
            </div>
          </div>
        </Link>
      </section>
      </section>
    </div>
  );
}

export default Dex;