import React from 'react';
import Btn from './btn';
import Rellax from 'react-rellax';

function Card({
  idNum,
  name,
  date,
  type,
  description,
  image,
  alt,
  bg,
  fontColor,
  blockcolor,
  cName,
  worktype
}) {

  let projectStyle = {
    backgroundImage: 'url(' + bg + ')',
    color: fontColor,
  };

  // window.addEventListener('load', init);

  function init() {
    // reportWindowSize();
    // window.addEventListener('resize', reportWindowSize);
  }

  // function changemenu(e) {
  //   let name = e.currentTarget;
  //   let cls = name.querySelector('#colorblock').className;
  //   document.getElementById('color-expand').classList.add(cls);
  // }

  // function reportWindowSize() {
  //   let pageWidth = window.innerWidth;
  //   // let desc = document.querySelectorAll('#desc');
  //   // let tab = document.getElementById('tab');
  //   // if (pageWidth <= 1000) {
  //   //   tab.classList.add('hidden');
  //   // } else {
  //   //   tab.classList.remove('hidden');
  //   // }
  //   if (pageWidth <= 450) {
  //     for (let i = 0; i < desc.length; i++) {
  //       desc[i].classList.add('hidden');
  //     }
  //   } else {
  //     for (let j = 0; j < desc.length; j++) {
  //       desc[j].classList.remove('hidden');
  //     }
  //   }
  // }

  return (
    <div data-aos='slide-up'>
      <section id={idNum} className='project' style={projectStyle}>
        <section>
          <h1>{name}</h1>
          <p id='type'>{type}</p>
          <div>
            <p id='desc'>{description}</p>
          </div>
          <img src={image} alt={alt}></img>
          <p id='date'><em>{date}</em></p>
        </section>
        <p id='num'>0{idNum} &mdash; {worktype}</p>
        <Btn cNamer={cName}/>
        <div id='colorblock' className={blockcolor}></div>
      </section>
    </div>
  );
}

export default Card;