import React from 'react';
import gsap from 'gsap';
import Rellax from 'react-rellax';
import { FiChevronLeft } from "react-icons/fi";
import { FaLongArrowAltLeft, FaLongArrowAltRight} from 'react-icons/fa'
import { Link } from 'react-router-dom';

function F1() {
  window.scrollTo(0, 0);
  setTimeout(function() {
    animate();
  }, 0);
  // window.addEventListener('load', animate);
  window.addEventListener('scroll', detailAnimate);
  // window.addEventListener('resize', () => {
  //   window.location.reload(true);
  // });

  async function animate() {
    document.querySelector('#proj-cover img').classList.remove('hidden');
    document.querySelector('.backbtn').classList.remove('hidden');
    await document.querySelector('#proj-cover').classList.add('f1hero');
    let t1 = gsap.timeline();
    t1.from('#proj-overview', {y: 150, opacity: 0, duration: .6, ease: 'power1.out'});
    t1.from('.proj-revealer', {width: '101%', duration: .5, ease: 'power1.out', delay: .3});
    t1.from('#proj-cover img', {y: -200, opacity: 0, duration: 1, ease: "elastic.out(1, 0.6)"});
    t1.from('.backbtn', {x: -100, opacity: 0, duration: .3, ease: 'power1.out'});
  }

  function detailAnimate() {
    let t2 = gsap.timeline();
    t2.from('.detail', {y: 50, opacity: 0, duration: 1, stagger: .4, delay: .3});
    window.removeEventListener('scroll', detailAnimate);
  }

return (
    <div className='App'>
      <section id='proj-intro'>
        <Link to='/'>
          <button className='backbtn hidden'><FiChevronLeft/>Home</button>
        </Link>
        <div id='proj-cover'>
          <div className='proj-revealer'/>
          <Rellax speed={-3}>
            <img className='hidden' src='images/f1logowhite.png' alt ='Tidy case study project'/>
          </Rellax>
        </div>
      </section>

      <section id='proj-overview'>
          <div id='proj-name'>
            <div>
              <h2 id='number'>03</h2>
              <p>Internship</p>
              <h1>Forum One</h1>
            </div>
          </div>
          <div id='proj-details'>
            <div className='detail'>
              <p>ROLE</p>
              <p>UX Designer</p>
            </div>
            <div className='detail'>
              <p>DURATION</p>
              <p>6 Months</p>
            </div>
            <div className='detail'>
              <p>DATE</p>
              <p>Jun, 2019</p>
            </div>
          </div>
        </section>

      <section className='content'>
        <div id='introduction' className='process-section'>
          <div className='process-title'>
            <p>00 /</p>
            <h1>Introduction</h1>
          </div>
          <div className='process-text'>
            <p>Forum One is a digital agency that helps nonprofits
              and goverment organizations extend their influence. They help mission
              driven agencies design for the future, challenging them to think about how they want
              their brand to be represented tomorrow and 10 years from now.</p>
          </div>
        </div>

        <div id='role' className='process-section'>
          <div className='process-title'>
            <p>01 /</p>
            <h1>My Role</h1>
          </div>
          <div className='process-text'>
            <h2>UX Design Intern</h2>
            <p>Hired on as the UX Design intern, I was blessed to work along side many talented designers, developers,
              project managers, and marketing specialists. During my internship I participated in
              discovery workshops (user research workshops), observed usability testing, helped
              consolidate findings into personas and journey maps, developed wireframes and interactive
              prototypes, and designed moodboards to be used in proposal letters.</p>

            <p>While I did a lot of client side work during my internship I also had the opportunity to
              work on internal projects. I think people underestimate the value of internal work.
              As an intern some of the most impactful moments came from 1 on 1 interactions I had with other
              employees at the company. They taught me about the industry, common trends, best practices,
              career advice, the importance of goal setting, and the value of communication.</p>

            <p>Some of the deliverables I produced I am not permitted to show as I do not own the rights
              to them, but I'd be more than happy to talk about them if you would like to learn more.</p>

            <h2>My Work</h2>
            <p>For a snapshot of some of my work check out my <a href="https://www.behance.net/brentmwong" target="_open">
              <span id='f1S' className='highlight'><b>Behance</b></span></a></p>
          </div>
          <div className='full-width'>
            <img src="https://i.imgur.com/iFUWFGk.png" alt="Frist moodboard"/>
            <img src="https://i.imgur.com/nNDzGFk.jpg" alt="Clinton Foundation Moodboard"/>
          </div>
        </div>

        <div id='work' className='process-section'>
          <div className='process-title'>
            <p>02 /</p>
            <h1>Reflection</h1>
          </div>
          <div className='process-text'>
            <p>In many ways this was my first real internship and it was by far the most
              rewarding work experience I've had. One of the biggest lessons I'm walking
              away with is to "make time." Make time to look at inspiration, make time
              to get to know your peers, and make time to grab lunch! It might sound a
              bit cliche but as an intern I always felt pressured to give
              it 110% from the moment I stepped foot into the office. Often times I would
              skip lunch and bury myself in work that I still had weeks to complete.
              Overtime I grew exhausted, I ran into designers
              block, I couldn't focus, and work was starting to feel like a chore.</p>

            <p>It wasn't until I had a meeting with one of my mentors who told me to make time
              to do other things. By constraining myself to work I was missing out on
              inspiration from outside sources, connections with incredible individuals,
              and unique perspectives from the unlikliest of places.</p>

            <p>Huge thank you to <a href="https://www.forumone.com/team/courtney-clark/" target="_open">
              <span id='f1S' className='highlight'><b>Courtney Clark</b></span></a>, and&nbsp;
              <a href="https://www.forumone.com/team/nicole-coumes/" target="_open">
              <span id='f1S' className='highlight'><b>Nicole Coumes</b></span></a>,
              for being so supportive and mentoring me throughout my time at Forum One.</p>
          </div>
        </div>

        <section id='otherproj'>
        <Link to='/tidy'>
          <div id='previous' className='cta'>
            <div>
              <img src='images/tidycardimg.png' alt='Tidy Case Study'/>
            </div>
            <div>
              <p>PREVIOUS</p>
              <h3>Tidy &mdash; Case Study</h3>
              <FaLongArrowAltLeft/>
            </div>
          </div>
        </Link>
        <Link to='/yoglo'>
          <div id='next' className='cta'>
            <div>
              <img src='images/yoglocardimg.png' alt='Yoglo Case Study'/>
            </div>
            <div>
              <p>UP NEXT</p>
              <h3>Yoglo &mdash; Case Study</h3>
              <FaLongArrowAltRight/>
            </div>
          </div>
        </Link>
      </section>
      </section>
    </div>
  );
}

export default F1;