import React from 'react';
import gsap from 'gsap';
import Rellax from 'react-rellax';
import { FiChevronLeft } from "react-icons/fi";
import { FaLongArrowAltLeft, FaLongArrowAltRight} from 'react-icons/fa'
import { Link } from 'react-router-dom';

function Bnb() {
  window.scrollTo(0, 0);
  setTimeout(function() {
    animate();
  }, 0);
  // window.addEventListener('load', animate);
  window.addEventListener('scroll', detailAnimate);
  // window.addEventListener('resize', () => {
  //   window.location.reload(true);
  // });

  function animate() {
    document.querySelector('#proj-cover img').classList.remove('hidden');
    document.querySelector('.backbtn').classList.remove('hidden');
    document.querySelector('#proj-cover').classList.add('bnbhero');
    let t1 = gsap.timeline();
    t1.from('#proj-overview', {y: 150, opacity: 0, duration: .6, ease: 'power1.out'});
    t1.from('.proj-revealer', {width: '101%', duration: .5, ease: 'power1.out', delay: .3});
    t1.from('#proj-cover img', {y: -200, opacity: 0, duration: 1, ease: "elastic.out(1, 0.6)"});
    t1.from('.backbtn', {x: -100, opacity: 0, duration: .3, ease: 'power1.out'});
  }

  function detailAnimate() {
    let t2 = gsap.timeline();
    t2.from('.detail', {y: 50, opacity: 0, duration: 1, stagger: .4, delay: .3});
    window.removeEventListener('scroll', detailAnimate);
  }

return (
    <div className='App'>
      <section id='proj-intro'>
        <Link to='/'>
          <button className='backbtn hidden'><FiChevronLeft/>Home</button>
        </Link>
        <div id='proj-cover'>
          <div className='proj-revealer'/>
          <Rellax speed={-3}>
            <img className='hidden' src='images/journeymockup.png' alt='Journey mockup'/>
          </Rellax>
        </div>
      </section>

      <section id='proj-overview'>
          <div id='proj-name'>
            <div>
              <h2 id='number'>05</h2>
              <p>Product Design</p>
              <h1>Journey</h1>
            </div>
          </div>
          <div id='proj-details'>
            <div className='detail'>
              <p>ROLE</p>
              <p>Product Designer</p>
            </div>
            <div className='detail'>
              <p>DURATION</p>
              <p>2 Days</p>
            </div>
            <div className='detail'>
              <p>DATE</p>
              <p>Apr, 2020</p>
            </div>
          </div>
        </section>

      <section className='content'>
        <div id='introduction' className='process-section'>
          <div className='process-title'>
            <p>00 /</p>
            <h1>Introduction</h1>
          </div>
          <div className='process-text'>
            <h2>Design Challenge</h2>
            <p>
              Empower families and groups of friends who travel together a collaborative way to document,
              organize, and share their travel experiences and stays to the larger Airbnb community.
            </p>
          </div>
        </div>

        <div id='Ideation' className='process-section'>
          <div className='process-title'>
            <p>01 /</p>
            <h1>Day 1 - Ideation</h1>
          </div>
          <div className='process-text'>
            <p>
              Day 1 was dedicated solely for gathering inspiration, brainstorming features, and
              ideating solutions.
            </p>
            <h2>Key Features</h2>
            <p>
              Based on the design challenge we concluded that the overall theme was centered around
              sharing experiences. As a result we came up with the following features to include in our
              design:
            </p>
            <ul>
              <li>Browse other people's travel experiences.</li>
              <li>Search destinations and attractions.</li>
              <li>Create and edit new scrapbooks.</li>
              <li>Share scrapbooks with friends and families.</li>
              <li>Save and comment on other people's trips.</li>
            </ul>
            <h2>User Flow</h2>
            <p>
              Knowing what we were going to include we quickly drew out a simple user flow.
            </p>
          </div>
          <img src='images/journeyuserflow.png' alt='user flow'/>
          <div className='process-text'>
            <h2>Inspiration</h2>
            <p>
              As a team we gathered inspiration from places like dribble and pinterest and put them
              into an InVision board.
            </p>
          </div>
          <img src='images/journeyinsp.png' alt='inspiration'/>
        </div>

        <div id='Design' className='process-section'>
          <div className='process-title'>
            <p>02 /</p>
            <h1>Day 2 - Design</h1>
          </div>
          <div className='process-text'>
            <p>
              Day 2 was all about the design and prototype. Given our limited time we did our best
              to maintain design consistency.
            </p>
          </div>

          <div className='mockupVid bnbhero'>
            <video autoPlay muted loop playbackRate='2' className="mockupVideo">
              <source src="images/journeydemo.mp4" type="video/mp4"/>
            </video>
            <div className='videotxt'>
              <h2 id='defaultS' className='highlight'>Overview</h2>
              <p>
                Journey aims to transform how people document, share, and learn about new travel
                experiences.
              </p>
            </div>
          </div>

          <div className='mockupVid bnbhero reverse'>
            <video autoPlay muted loop playbackRate='2' className="mockupVideo">
              <source src="images/journeybrowse.mp4" type="video/mp4"/>
            </video>
            <div className='videotxt'>
              <h2 id='defaultS' className='highlight'>Immerse Yourself</h2>
              <p>
                Find inspiration for traveling by browsing and saving the personal experiences of others.
              </p>
            </div>
          </div>

          <div className='mockupVid bnbhero'>
            <video autoPlay muted loop playbackRate='2' className="mockupVideo">
              <source src="images/journeyscrap.mp4" type="video/mp4"/>
            </video>
            <div className='videotxt'>
              <h2 id='defaultS' className='highlight'>Create Journeys</h2>
              <p>
                Invite friends and family collaborate on your journey through a simple link.
                Together upload memories, write comments, or give advice about your trips.
              </p>
            </div>
          </div>

          <div className='mockupVid bnbhero reverse'>
            <video autoPlay muted loop playbackRate='2' className="mockupVideo">
              <source src="images/journeyreminisce.mp4" type="video/mp4"/>
            </video>
            <div className='videotxt'>
              <h2 id='defaultS' className='highlight'>Reminisce</h2>
              <p>
                Relive your past adventures and view your travel log.
              </p>
            </div>
          </div>
        </div>

        <div id='work' className='process-section'>
          <div className='process-title'>
            <p>03 /</p>
            <h1>Reflection</h1>
          </div>
          <div className='process-text'>
            <p>
              This was my first time participating in a design jam and while it felt like a huge
              time crunch and was a bit stressful at times, I enjoyed the challenge. It forced us
              to quickly iterate through designs and ideas helping to develop my creative thinking.
            </p>
            <p>
              Shout out to my teammates&nbsp;
              <span id='defaultS' className='highlight'><b>Daniel Nguyen</b></span>, and&nbsp;
              <span id='defaultS' className='highlight'><b>Lena Tran</b></span>&nbsp;
              for staying up until 5am to finish this project.</p>
          </div>
        </div>

        <section id='otherproj'>
        <Link to='/yoglo'>
          <div id='previous' className='cta'>
            <div>
              <img src='images/yoglocardimg.png' alt='Airbnb creative jam'/>
            </div>
            <div>
              <p>PREVIOUS</p>
              <h3>Yoglo &mdash; Case Study</h3>
              <FaLongArrowAltLeft/>
            </div>
          </div>
        </Link>
        <Link to='/set'>
          <div id='next' className='cta'>
            <div>
              <img src='images/set.png' alt='pokedex web development assignment'/>
            </div>
            <div>
              <p>UP NEXT</p>
              <h3>Set &mdash; Card Game</h3>
              <FaLongArrowAltRight/>
            </div>
          </div>
        </Link>
      </section>
      </section>
    </div>
  );
}

export default Bnb;