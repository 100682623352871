import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import './about.css';
import './project.css';
import Nav from './comp/nav';
import Home from './pages/home';
import About from './pages/about';
import Creative from './pages/creative';
import Becu from './pages/becu';
import F1 from './pages/forumone';
import Tidy from './pages/tidy';
import Yoglo from './pages/yoglo';
import Dex from './pages/pokedex';
import Set from './pages/set';
import Bnb from './pages/bnb';
import AOS from 'aos';

function App() {

  AOS.init({
    duration: 1000,
    disable: false,
    initClassName: 'aos-init',
    once: true,
  });

  return (
    <div className="App">
      <Router>
        <Nav />
        <Switch>
          <Route path='/' exact component={Home}/>
          <Route path='/about' component={About}/>
          <Route path='/becu' component={Becu}/>
          <Route path='/forumone' component={F1}/>
          <Route path='/tidy' component={Tidy}/>
          <Route path='/yoglo' component={Yoglo}/>
          <Route path='/creative' component={Creative}/>
          <Route path='/pokedex' component={Dex}/>
          <Route path='/set' component={Set}/>
          <Route path='/airbnb' component={Bnb}/>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
