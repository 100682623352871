import React from 'react';
import gsap from 'gsap';
import Rellax from 'react-rellax';
import { FiChevronLeft } from "react-icons/fi";
import { FaLongArrowAltLeft, FaLongArrowAltRight} from 'react-icons/fa'
import { Link } from 'react-router-dom';

function Becu() {
  window.scrollTo(0, 0);
  setTimeout(function() {
    animate();
  }, 0);
  // window.addEventListener('load', animate);
  window.addEventListener('scroll', detailAnimate);
  // window.addEventListener('resize', () => {
  //   window.location.reload(true);
  // });

  async function animate() {
    document.querySelector('#proj-cover img').classList.remove('hidden');
    document.querySelector('.backbtn').classList.remove('hidden');
    await document.querySelector('#proj-cover').classList.add('becuhero');
    let t1 = gsap.timeline();
    t1.from('#proj-overview', {y: 150, opacity: 0, duration: .6, ease: 'power1.out'});
    t1.from('.proj-revealer', {width: '101%', duration: .5, ease: 'power1.out', delay: .3});
    t1.from('#proj-cover img', {y: -200, opacity: 0, duration: 1, ease: "elastic.out(1, 0.6)"});
    t1.from('.backbtn', {x: -100, opacity: 0, duration: .3, ease: 'power1.out'});
  }

  function detailAnimate() {
    let t2 = gsap.timeline();
    t2.from('.detail', {y: 50, opacity: 0, duration: 1, stagger: .4, delay: .3});
    window.removeEventListener('scroll', detailAnimate);
  }

return (
    <div className='App'>
      <section id='proj-intro'>
        <Link to='/'>
          <button className='backbtn hidden'><FiChevronLeft/>Home</button>
        </Link>
        <div id='proj-cover'>
          <div className='proj-revealer'/>
          <Rellax speed={-3}>
            <img className='hidden' src='images/becugroupmockup.png' alt ='Becu Senior Capstone Project'/>
          </Rellax>
        </div>
      </section>

      <section id='proj-overview' className='hidden'>
          <div id='proj-name'>
            <div>
              <h2 id='number'>01</h2>
              <p>Case Study</p>
              <h1>BECU</h1>
            </div>
          </div>
          <div id='proj-details'>
            <div className='detail'>
              <p>ROLE</p>
              <p>Product Designer</p>
            </div>
            <div className='detail'>
              <p>DURATION</p>
              <p>10 Weeks</p>
            </div>
            <div className='detail'>
              <p>DATE</p>
              <p>Apr, 2020</p>
            </div>
          </div>
        </section>

      <section className='content'>
        <div id='introduction' className='process-section'>
          <div className='process-title'>
            <p>00 /</p>
            <h1>Introduction</h1>
          </div>
          <div className='process-text'>
            <p>
              As my final year in college came to an end it was time to apply my skills one last time in the
              form of a senior project.
            </p>
            <p>
              Our project was sponsored by the UX team over at BECU. Initially they proposed a project that
              was aimed at improving the financial literacy of teens and young adults, but decided to open
              up the project to us so long as it pertained to finances and young adults.
            </p>
            <p>
              <em><u>Note:</u></em><br/>
              <span id='becuS' className='highlight'>
                In the wake of the Covid-19 pandemic our team was forced to adjust to strategy to
                accommodate for a more remote environment.
              </span>
            </p>
          </div>
        </div>

        <div id='prelim' className='process-section'>
          <div className='process-title'>
            <p>01 /</p>
            <h1>Identify</h1>
          </div>
          <div className='process-text'>
            <h2>Picking a project direction</h2>
            <p>
              In our first meeting the team at BECU challenged us to come up with 3 distinct design
              challenges as they relate to teens and finances.
            </p>

            <h2>Preliminary research</h2>
            <p>
              To get an idea of what problem spaces exist we conducted a very short survey asking the
              following questions:
              <ol>
                <li>
                  When it comes to finances, what are your biggest concerns? Why?
                </li>
                <li>
                  Do you pay attention to reward systems when it comes to using credit cards?
                  (ie. cashback, airline mileage, etc.)
                </li>
                <li>
                  Do you use a banking app/budgeting app? How useful is it? Why do you think so?
                </li>
              </ol>
            </p>

            <h2>Project ideas</h2>
            <p>
              By the end of our survey we settled on 3 ideas: A budgeting app for young adults, a financial
              literacy game, and BECU branch space redesign.
            </p>
          </div>
        </div>

        <div id='research' className='process-section'>
          <div className='process-title'>
            <p>02 /</p>
            <h1>Research</h1>
          </div>
          <div className='process-text'>
            <p>
              After deliberating with the BECU team we decided that a budgeting app tailored for
              young adults not only addressed the goal of improving financial literacy, but also
              would promote smart finanical behavior.
            </p>
            <h2>Interviews</h2>
            <p>
              To better understand how young adults budget and save money we set up a total of
              10 interviews.
            </p>
            <p>Participants characteristics:</p>
            <ul>
              <li>Between the ages 18-24</li>
              <li>Any socioeconomic status</li>
              <li>Any gender or ethnicity</li>
              <li>Any level of financial literacy</li>
            </ul>
          </div>
          <img src='images/becuinterviews.png' alt='Zoom Interviews'/>
          <div className='process-text'>
            <h2>Affinity Diagram</h2>
            <p>
              The next step was to consolidate all the data from our interviews into something more
              digestible. Our team made a digital version
              using Miro.
            </p>
            <p>
              <a id='becuS' href='https://miro.com/app/board/o9J_ktvwXcU=/' className='highlight' target='_open'>
                View diagram here
              </a>
            </p>
          </div>
          <img src='images/becuaffinity.png' alt='Affinity Diagram'/>
          <div className='barsection'>
            <h3>HIGH LEVEL THEMES</h3>
            <div className='tidygrid'>
              <div className='finding'>
                <h4><strong>01. </strong>Helpful Visuals</h4>
                <p id='becuS'>Having meaningful visuals can be beneficial for organization and comprehension.</p>
              </div>
              <div className='finding'>
                <h4><strong>02. </strong>Social Aspect</h4>
                <p id='becuS'>Most young adults learn about finances from their parents, peers, or mentors.</p>
              </div>
              <div className='finding'>
                <h4><strong>03. </strong>No Added Value</h4>
                <p id='becuS'>Why use a 3rd party app when spreadsheets or pen &amp; paper work fine.</p>
              </div>
              <div className='finding'>
                <h4><strong>04. </strong>Desire to Learn</h4>
                <p id='becuS'>When asked many young adults suggested that they would like to learn more about
                investing and how to prepare for their future.</p>
              </div>
              <div className='finding'>
                <h4><strong>05. </strong>Not Relevant Enough</h4>
                <p id='becuS'>Many teens are still dependent on their parents and as a result aren't too
                concerned about long term financial planning.
                </p>
              </div>
              <div className='finding'>
                <h4><strong>06. </strong>Leisurely Spending</h4>
                <p id='becuS'>Teens acknowledged that they sometimes have a hard time with leisurely
                  spending and impulsive buying.
                </p>
              </div>
            </div>
          </div>
          <div className='process-text'>
            <h2>Competitive Analysis</h2>
            <p>
              There are a plethora of financial tools currently on the market. It was important for us
              to do our homework in order to develop a framework of what works well and what doesn't.
            </p>
            <p>
              We analyzed critic reviews as well as app store reviews for 5 of the most popular
              budgeting apps including:
            </p>
            <ul>
              <li>Mint</li>
              <li>Greenlight</li>
              <li>Pocket Guard</li>
              <li>Personal Capital</li>
              <li>YNAB (You Need A Budget)</li>
            </ul>
            <p>
              <a id='becuS' href='https://www.dropbox.com/s/zxnnzvrgkuu6xju/Competitive%20Analysis%20Report.pdf?dl=0' className='highlight' target='_open'>
                Full Report
              </a>
            </p>
          </div>
          <img src='images/becuappreviews.png' alt='Various app reviews'/>
        </div>

        <div id='ideation' className='process-section'>
          <div className='process-title'>
            <p>03 /</p>
            <h1>Ideation</h1>
          </div>
          <div className='process-text'>
            <p>
              With a better understanding of our problem space we could begin scoping our problem,
              brainstorming ideas, and ideating solutions.
            </p>
            <h2>Design Requirements</h2>
            <p>Our system should...</p>
            <ol>
              <li>
                Incorporate visualizations as a way to help break things into categories and show
                spending habits.
              </li>
              <li>
                Track spending and support categorization of expenses. (daily, weekly, monthly).
              </li>
              <li>
                Support goal setting/progress tracking, budgeting. (short term, medium term, long term).
              </li>
              <li>
                Suggest ways to optimize spending on current monthly expenses.
              </li>
              <li>
                Allow users to share budgeting plans and allow commenting on budgeting plans.
              </li>
              <li>
                Have opportunities for users to learn about finances.
              </li>
            </ol>

            <h2>Information Architecture</h2>
            <p>
              To illustrate how our system would incorporate our design requirements and the various
              interactions associated with them we created an information architecture.
            </p>
          </div>
          <img src='images/becuIA.png' alt='BECU Information Architecture3'/>
          <div className='process-text'>
            <h2>BECU Feedback</h2>
            <p>
              We decided to consult with team at BECU so that we could present our research and design ideas.
            </p>
            <p>
              The team was very pleased with our findings as it corroborated the research they
              had already done in this area. As for the design, the team thought that we were being a
              bit ambitious and suggested that we pick one feature to design around (social, budgeting, or resources)
              instead of doing all three. Many of them were intrigued with our idea of making finances a bit
              more social as it would have a better chance of appealing to a younger audience.
            </p>
            <p>
              They also left us with this closing remark, "<span id='becuS' className='highlight'>What people say they'll do is sometimes
              different from what they'll actually do.</span>"
            </p>
          </div>
          <img src='images/becufeedback.png' alt='BECU mural feedback'/>
        </div>

        <div id='iteration' className='process-section'>
          <div className='process-title'>
            <p>04 /</p>
            <h1>Iteration</h1>
          </div>
          <div className='process-text'>
            <h2>New Direction</h2>
            <p>
              With the feedback fresh in our minds we decided to conduct a short 8 question survey verifying
              whether or not teens and young adults actually have a desire to budget and what their thoughts were
              on making finances more social.
            </p>
            <p>
              The general understanding from this survey was that teens and young adults don't budget, and if they
              do they follow very simple one's that would not require a 3rd party app. They also
              were intrigued by the idea of making finances more collaborative as many of them
              already think highly of apps like Venmo. For this reason we scrapped the idea for a budgeting
              app and reoriented it to focus on the social aspect.
            </p>

            <blockquote>
              <p>
                "Collaborative financial management using apps such as Venmo is something that I fully
                support! I believe that a lot of people are open to receiving or giving money to
                others to reach their financial goals and there needs to me more ways for more
                complete financial collaboration..."
              </p>
              <cite>— Participant 7</cite>
            </blockquote>

            <h2>Revised IA</h2>
            <p>
              We revised our information architecture to reflect our new design direction (social finances).
            </p>
          </div>
          <img src='images/becunewIA.png' alt='revised IA'/>
        </div>

        <div id='prototype' className='process-section'>
          <div className='process-title'>
            <p>05 /</p>
            <h1>Prototyping</h1>
          </div>
          <div className='process-text'>
            <h2>Wireframes</h2>
            <p>
              With our system mapped out we were finally able to start designing solutions. Figma was our
              software of choice as it allows for a collaborative workspace. We created
              rather detailed wireframes as we planned to use them as our prototype once finished.
            </p>
          </div>
          <img src='images/becuwireframes.png' alt='BECU wireframes'/>
          <div className='process-text'>
            <h2>Usability Testing</h2>
            <p>
              The final step was to add a few simple interactions and voila! our we were ready to begin
              testing our design.
            </p>
            <p>
              To test our prototype we referred back to 4 of the same individuals that we interviewed
              with. We also showcased our prototype to the BECU team who gave us valuable feedback in
              regards to accessibility issues.
            </p>
            <a
              href='https://www.figma.com/proto/NIHedbFP4Hb8et414xiaQx/BECU-Wireframes?node-id=19%3A0&viewport=383%2C43%2C0.0412462092936039&scaling=scale-down'
              target='_open'
              id='becuS'
              className='highlight'
            >
              View Interactive Figma Prototype
            </a>
          </div>
          <img src='images/becuproto.png' alt='usability testing'/>
          <div className='process-text'>
            <p>
              By the end of our testing we curated a list of changes to make suggested by our users including:
            </p>
            <ul>
              <li>Provide options for more privacy</li>
              <li>Create a confirmation screen for any transfers/transactions</li>
              <li>More visual depictions of expenses</li>
              <li>Signal for unfilled input fields</li>
              <li>Add date calander instead of dropdown</li>
              <li>Rethink placement of create button</li>
              <li>Improved hierarchy</li>
            </ul>
          </div>
        </div>
        <div id='design' className='process-section'>
          <div className='process-title'>
            <p>06 /</p>
            <h1>Design</h1>
          </div>
          <div className='process-text'>
            <h2>Guidelines</h2>
            <p>
              None! BECU gave us full autonomy over our project and actually encouraged us to go
              off brand, which is exactly what we did.
            </p>

            <h2>Reimagined Brand</h2>
            <p>
              Since our target audience was young adults between the ages of 18-24 we wanted
              a color palatte, font, and set of icons that felt a bit more youthful and exciting.
            </p>
          </div>

          <section id='colorpalette'>
            <div className='grid-section1 blue'>
              <p id='html'>#0D144D</p>
            </div>
            <div className='grid-section1 yellow'>
              <p id='css'>#FFB200</p>
            </div>
            <div className='grid-section1 green'>
              <p id='js'>#00CD86</p>
            </div>
            <div className='grid-section1 red'>
              <p id='react'>#FF2960</p>
            </div>
          </section>
          <img src='images/becustyleguide.png' alt='style guide'/>
          <div className='mockupVid becuhero'>
            <video autoPlay muted loop className="mockupVideo">
              <source src="images/becudemo.mp4" type="video/mp4"/>
            </video>
            <div className='videotxt'>
              <h2 id='becuS' className='highlight'>Overview</h2>
              <p>CO | OP is a mobile app designed to encourage collaboration and promote financial
                accountability in young adults.</p>
            </div>
          </div>

          <div className='mockupVid becuhero reverse'>
            <video autoPlay muted loop className="mockupVideo">
              <source src="images/becucreategoal.mp4" type="video/mp4"/>
            </video>
            <div className='videotxt'>
              <h2 id='becuS' className='highlight'>Create Goals</h2>
              <p>Create individual or group goals and track your progress towards achieving them.</p>
            </div>
          </div>

          <div className='mockupVid becuhero'>
            <video autoPlay muted loop className="mockupVideo">
              <source src="images/becuexpenses.mp4" type="video/mp4"/>
            </video>
            <div className='videotxt'>
              <h2 id='becuS' className='highlight'>Manage Expenses</h2>
              <p>Create and manage shared expenses amongst peers and help maintain accountability.</p>
            </div>
          </div>

          <div className='mockupVid becuhero reverse'>
            <video autoPlay muted loop className="mockupVideo">
              <source src="images/becusocial.mp4" type="video/mp4"/>
            </video>
            <div className='videotxt'>
              <h2 id='becuS' className='highlight'>Share Goals</h2>
              <p>Share your goals with your close network and help support others on their paths
                towards their financial goals!
              </p>
            </div>
          </div>

        </div>

        <div id='reflection' className='process-section'>
          <div className='process-title'>
            <p>07 /</p>
            <h1>Reflection</h1>
          </div>
          <div className='process-text'>
            <h2>Unexpected Challenge</h2>
            <p>
              If there's one takeaway that I gained from this experience, it's the confidence in my
              abilities to adapt and operate in a remote setting. Nothing could've prepared us for
              the challenges that Covid-19 brought with it. Not only did we have learn to work remotely, but
              with the lack of physcial presence we had to find ways to keep each other accountable.
              Needless to say there were a lot of late night zoom calls and unhealthy amounts of group chats.
            </p>
            <p>
              Special thanks to BECU for sponsoring our project and big shoutout to my teammates <a href="http://jaesuklee.me/" target="_open">
              <span id='becuS' className='highlight'><b>Jaesuk Lee</b></span></a>,&nbsp;
              <span id='becuS' className='highlight'><b>Vy Pham</b></span>, and&nbsp;
              <span id='becuS' className='highlight'><b>Robert Bennett</b></span>&nbsp;
              for powering through the adversity in order to deliver a successful project.</p>
          </div>
        </div>

        <section id='otherproj'>
        <Link to='/pokedex'>
          <div id='previous' className='cta'>
            <div>
              <img src='images/pokemon.png' alt='Pokedex Web Development'/>
            </div>
            <div>
              <p>PREVIOUS</p>
              <h3>Pokedex &mdash; Web Development</h3>
              <FaLongArrowAltLeft/>
            </div>
          </div>
        </Link>
        <Link to='/tidy'>
          <div id='next' className='cta'>
            <div>
              <img src='images/tidycardimg.png' alt='Tidy Case Study'/>
            </div>
            <div>
              <p>UP NEXT</p>
              <h3>Tidy &mdash; Case Study</h3>
              <FaLongArrowAltRight/>
            </div>
          </div>
        </Link>
      </section>
      </section>
    </div>
  );
}

export default Becu;